import navigation from "./Navigation";
import artists from "./Artists";
import artPools from "./ArtPools";
import artPool from "./ArtPool";
import persons from "./Persons";
import person from "./Person";
import users from "./Users";
import user from "./User";
import timer from "./Timer";

export default {
  navigation,
  artists,
  artPools,
  persons,
  person,
  users,
  user,
  timer,
  artPool
};
