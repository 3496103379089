export function get() {
  return {
    type: "USERS_GET_PUBLIC",
    payload: {}
  };
}
export function pending() {
  return {
    type: "USERS_SET_PENDING",
    payload: {}
  };
}
export function error() {
  return {
    type: "USERS_SET_ERROR",
    payload: {}
  };
}
