import { Authentication } from "@datenbanker/devcloud-client-lib";
import { pending, error } from "../actions/Users";

const webcollectorUserPool = "f837d4d5-4040-4b72-a8ad-214cb5570e43";

export default {
  USERS_GET_PUBLIC: async (store, next, action) => {
    next(pending());
    const auth = new Authentication({ user: webcollectorUserPool });
    try {
      const users = await auth.user.getAllPublic(webcollectorUserPool);
      action.payload = { users, receivedAt: new Date() };
      next(action);
    } catch (err) {
      return next(error());
    }
  }
};
