export function get(id) {
  return {
    type: "ART_POOL_GET",
    payload: { id }
  };
}
export function update(set, id) {
  return {
    type: "ART_POOL_UPDATE",
    payload: { set, id }
  };
}
export function errors(errors) {
  return {
    type: "ART_POOL_ERRORS",
    payload: { errors }
  };
}
export function pending() {
  return {
    type: "ART_POOL_PENDING",
    payload: {}
  };
}
export function inviteMember(user, pool) {
  return {
    type: "ART_POOL_MEMBER_INVITE",
    payload: { user, pool }
  };
}
export function addMember(member, pool) {
  return {
    type: "ART_POOL_MEMBER_ADD",
    payload: { member, pool }
  };
}
export function addMemberError(error) {
  return {
    type: "ART_POOL_MEMBER_ADD_ERROR",
    payload: { ...error }
  };
}
export function deleteMember(user, pool) {
  return {
    type: "ART_POOL_MEMBER_DELETE",
    payload: { user, pool }
  };
}
export function memberDeleteError() {
  return {
    type: "ART_POOL_MEMBER_DELETE_ERROR",
    payload: {}
  };
}
