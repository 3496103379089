import { Art } from "@datenbanker/devcloud-client-lib";
import {
  pending,
  errors as errorsAction,
  addMemberError,
  memberDeleteError
} from "../actions/ArtPool";

export default {
  ART_POOL_GET: async (store, next, action) => {
    next(pending());
    try {
      const art = new Art();
      const pool = await art.pool.my(action.payload.id);
      action.payload.values = pool;
      return next(action);
    } catch (error) {
      return false;
    }
  },
  ART_POOL_UPDATE: async (store, next, action) => {
    try {
      const art = new Art();
      await art.pool.myUpdate(action.payload.id, action.payload.set);
      action.payload = { values: { ...action.payload.set } };
      return next(action);
    } catch (error) {
      if (error.code === "validationError") {
        const convertErrorCode = code => {
          switch (code) {
            case "alreadyExists":
              return "Existiert bereits.";
            case "notSet":
              return "Ist ein Pflichtfeld.";
            case "notString":
              return "Enthält ungültige Zeichen.";
            case "notInt":
              return "Ist keine Zahl.";
            case "notEmail":
              return "Enthält keine gültige Email-Adresse.";
            case "notPhone":
              return "Enthält keine gültige Telefonnummer.";
            case "outOfRange":
              return "Muss mindesten 4 und maximial 155 lang sein.";
            default:
              return "Unbekannter Fehler. Code: " + code;
          }
        };
        let errors = {};
        error.errors.forEach(err => {
          errors[err.key] = convertErrorCode(err.error);
        });

        return next(errorsAction(errors));
      }
      return next(action);
    }
  },
  ART_POOL_MEMBER_ADD: async (store, next, action) => {
    const { pool } = action.payload;
    const { user, permission, type } = action.payload.member;
    try {
      const art = new Art();
      await art.pool.member.invite({ user, permission, type }, pool);
      return next(action);
    } catch (error) {
      return next(addMemberError(error));
    }
  },
  ART_POOL_MEMBER_DELETE: async (store, next, action) => {
    const { user, pool } = action.payload;
    try {
      const art = new Art();
      await art.pool.member.delete(user, pool);
      return next(action);
    } catch (error) {
      return next(memberDeleteError());
    }
  }
};
