const store = {
  values: {
    name: "",
    displayName: "",
    member: [],
    shared: 0,
    password: ""
  },
  errors: {
    name: "",
    displayName: "",
    members: [],
    shared: "",
    password: ""
  },
  pending: true,
  error: null,
  receivedAt: null,
  config: {
    types: [
      { label: "Besitzer", value: "owner" },
      { label: "Verwalter", value: "manager" },
      { label: "Leihnehmer", value: "borrower" },
      { label: "Dienstleister", value: "contractor" },
      { label: "Kommissieur", value: "commissure" },
      { label: "Mieter", value: "rentee" }
    ]
  }
};

const reducer = {
  ART_POOL_GET: (state, action) => {
    const { payload } = action;
    state.values = { ...state.values, ...payload.values };
    state.values.member = state.values.member.map(item => ({
      ...item,
      deleteError: false
    }));
    state.pending = false;
    state.receivedAt = new Date();
    return state;
  },
  ART_POOL_UPDATE: (state, action) => {
    const { payload } = action;
    if (payload.values.member)
      payload.values.member = state.values.member.map(mem => {
        const update = payload.values.member.find(item => item.id === mem.id);
        if (update) {
          mem = { ...mem, ...update };
        }
        return mem;
      });

    state.values = { ...state.values, ...payload.values };
    for (let field in payload.values) {
      switch (field) {
        case "id":
          break;
        case "member":
          break;
        default:
          state.errors[field] = "";
      }
    }
    return state;
  },
  ART_POOL_ERRORS: (state, action) => {
    const { payload } = action;
    state.errors = { ...state.errors, ...payload.errors };
    return state;
  },
  ART_POOL_PENDING: (state, action) => {
    state.pending = true;
    return state;
  },
  ART_POOL_MEMBER_ADD: (state, action) => {
    const { member } = action.payload;
    state.values.member = [
      ...state.values.member,
      {
        id: member.user,
        displayName: member.name,
        permission: member.permission,
        type: member.type
      }
    ];
    return state;
  },
  ART_POOL_MEMBER_DELETE: (state, action) => {
    const { payload } = action;
    state.values.member = state.values.member.filter(member => {
      return member.id !== payload.user;
    });
    return state;
  },
  ART_POOL_MEMBER_DELETE_ERROR: (state, action) => {
    const { payload } = action;
    state.values.member = state.values.member.map(member => {
      if (member.id === payload.user) {
        return { ...member, deleteError: true };
      }
      return member;
    });
    return state;
  }
};

export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
