import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers";
import Storage from "@datenbanker/storage";

const storage = new Storage();

//pages
const store = {
  links: [
    {
      label: "Personen",
      icon: faUsers,
      group: "Person",
      path: "/",
      breadCrumbs: [{ title: "Personen", link: "/" }]
    },
    {
      label: "Neue Person",
      icon: faUsers,
      show: false,
      group: "Person",
      path: "/person/add",
      breadCrumbs: [
        { title: "Personen", link: "/" },
        { title: "Neue Person", link: "/person/add" }
      ]
    },
    {
      label: "Person",
      icon: faUsers,
      show: false,
      group: "Person",
      path: "/person/:id/edit",
      originalPaths: ["/person/:id/edit"],
      breadCrumbs: [{ title: "Personen", link: "/" }, { title: "Bearbeiten" }]
    }
  ],
  routes: [
    {
      component: async () => {
        return import("../resources/components/Persons/List");
      },
      group: "Person",
      paths: ["/"]
    },
    {
      component: async () => {
        return import("../resources/components/Persons/Add");
      },
      group: "Person",
      paths: ["/person/add"]
    },
    {
      component: async () => {
        return import("../resources/components/Persons/Edit/index");
      },
      group: "Person",
      paths: ["/person/:id/edit"]
    }
  ],
  signIn: false
};

const common = {
  setArtPoolLinks: (payload, link) => {
    const { receivedAt, pools } = payload;
    if (receivedAt !== null) {
      link.subMenu.links = pools.map(pool => ({
        title: "Kunstwerke",
        label: pool.displayName,
        path: "/art/" + pool.id,
        breadCrumbs: [{ title: "Kunstwerke" }, { title: pool.displayName }]
      }));
      link.receivedAt = receivedAt;
    }
    return link;
  }
};

const reducer = {
  SIGN_OUT: state => {
    storage.delete("user:accessToken");
    storage.delete("user:idToken");
    storage.delete("user:refreshToken");

    return state;
  },
  NAVIGATION_CHANGE_ART_POOL: (state, action) => {
    state.pages = state.pages.map(page => {
      if (page.id !== "art-collection") return page;
      const { payload } = action;
      const setTo = {
        ...page,
        current: payload.id,
        breadCrumbs: page.breadCrumbs.map((breadCrumb, index) => {
          if (page.breadCrumbs && index === page.breadCrumbs.length - 1)
            return { ...breadCrumb, title: payload.name };
          return { ...breadCrumb };
        })
      };
      return setTo;
    });
    return state;
  },
  ART_POOLS_GET: (state, action) => {
    const { index } = action.payload;

    let link = {
      ...(state.links.find(link => link.id === "art-collections") || {})
    };
    link = common.setArtPoolLinks(action.payload, link);

    state.links = [...state.links];
    state.links[index] = link;

    return state;
  },
  NAVIGATION_OPEN_ART: (state, action) => {
    const { index } = action.payload;
    let link = { ...state.links[index] };

    link.subMenu.pending = false;
    link.subMenu.show = true;
    link = common.setArtPoolLinks(action.payload, link);

    state.links = [...state.links];
    state.links[index] = link;

    return state;
  },
  NAVIGATION_CLOSE_ART: (state, action) => {
    state.links = [
      ...state.links.map(link => {
        if (link.id === "art-collections") {
          link.subMenu.show = false;
        }
        return { ...link };
      })
    ];
    return state;
  },
  NAVIGATION_SET_ART_PENDING: (state, action) => {
    const { index } = action.payload;
    let link = { ...state.links[index] };
    link.subMenu.pending = true;
    link.subMenu.show = true;
    state.links = [...state.links];
    state.links[index] = link;
    return state;
  }
};
export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
