import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { DevCloud } from "@datenbanker/devcloud-client-lib";
import { Theme, Authenticator } from "@datenbanker/react-devcloud-components";
import store from "./store";

DevCloud.init({
  apiToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhOWI1MzY1ZC05ZGRmLTQ3N2EtYTY4YS04ZGYyMTA4NjkzZDkiLCJzY29wZXMiOlsiYTQzZDcwOGUtODk0MC00NThlLWJiYWEtNjcxMWM1YTUwODM0Il19.cOfgpW4wNTFStc-GNiV6Cppd9onkYwCigHALxpwBpvg",
  services: {
    authentication: {
      user: {
        pool: ["1396be74-b975-42d2-8b85-5bdaccc9676c"] //0231a2b2-e877-4636-86d2-1e20b4d91838
      }
    },
    customer: {
      person: {
        pool: ["3ed14039-0f52-4388-99cf-60a2222cd973"]
      },
      opportunity: {
        pool: ["46321b9c-7abd-4c7d-8e1e-3c2c96e20c96"]
      },
      task: {
        pool: ["c057768a-58b1-4031-8385-b80109db1915"]
      }
    }
  },
  groups: {
    "46fc654e-efb7-46b3-be95-73e1cbb926fc": "Basic",
    "0bf6e2ce-cd51-4f9e-b331-1a0081e0d16d": "Person"
  },
  handler: {} // handler assigend in app component because they need redux
});
Theme.init();

ReactDOM.render(
  <Provider store={store}>
    <Authenticator>
      <App />
    </Authenticator>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
