export function getByPersonAndPool(person, pool) {
  return {
    type: "USER_GET",
    payload: { person, pool }
  };
}
export function reset() {
  return {
    type: "USER_RESET",
    payload: {}
  };
}
export function pending(type) {
  let pending = {
    activePending: type === "activate",
    disablePending: type === "disable",
    deletePending: type === "delete",
    groupsPending: type === "groups"
  };

  return {
    type: "USER_SET_PENDING_TYPES",
    payload: { ...pending }
  };
}
export function deleted() {
  return {
    type: "USER_DELETED",
    payload: {}
  };
}
export function update(fields, id) {
  return {
    type: "USER_UPDATE",
    payload: { fields, id }
  };
}
