const store = {
  users: null,
  pending: false,
  error: false,
  receivedAt: null
};

const reducer = {
  USERS_GET_PUBLIC: (state, action) => {
    state.users = action.payload.users;
    state.pending = false;
    state.error = false;
    state.receivedAt = action.payload.receivedAt;
    return state;
  },
  USERS_SET_PENDING: (state, action) => {
    state.pending = true;
    state.error = false;
    return state;
  },
  USERS_SET_ERROR: (state, action) => {
    state.error = true;
    state.pending = false;
    return state;
  }
};
export default (state = store, action) => {
  if (reducer[action.type]) return reducer[action.type]({ ...state }, action);
  return { ...state };
};
